<template>
  <section
    id="knowledge-base-content"
    class="px-4"
  >
    <b-row class="match-height">
      <b-col
        lg="3"
        cols="6"
      >
        <b-card
          body-class="pb-50"
        >
          <h6>Copropriétés</h6>
          <h2 class="font-weight-bolder mb-1">
            600
          </h2>
          <!-- chart -->
          <vue-apex-charts
            height="70"
            :options="statisticsOrder.chartOptions"
            :series="statisticsOrder.series"
          />
        </b-card>
      </b-col>
      <b-col
        lg="3"
        cols="6"
      >
        <b-card
          class="card-tiny-line-stats"
          body-class="pb-50"
        >
          <h6>Sociétés</h6>
          <h2 class="font-weight-bolder mb-1">
            6 000
          </h2>
          <!-- chart -->
          <vue-apex-charts
            height="70"
            :options="statisticsProfit.chartOptions"
            :series="statisticsProfit.series"
          />
        </b-card>
      </b-col>
      <b-col
        lg="3"
        cols="6"
      >
        <b-card
          body-class="pb-50"
        >
          <h6>Copropriétés</h6>
          <h2 class="font-weight-bolder mb-1">
            800
          </h2>
          <!-- chart -->
          <vue-apex-charts
            height="70"
            :options="statisticsOrder.chartOptions"
            :series="statisticsOrder.series"
          />
        </b-card>
      </b-col>
      <b-col
        lg="3"
        cols="6"
      >
        <b-card
          class="card-tiny-line-stats"
          body-class="pb-50"
        >
          <h6>Sociétés</h6>
          <h2 class="font-weight-bolder mb-1">
            6 000
          </h2>
          <!-- chart -->
          <vue-apex-charts
            height="70"
            :options="statisticsProfit.chartOptions"
            :series="statisticsProfit.series"
          />
        </b-card>
      </b-col>
    </b-row>
    <!--/ Miscellaneous Charts -->

    <b-row class="kb-search-content-info match-height">
      <b-col
        md="6"
        sm="6"
        class="kb-search-content"
      >
        <b-card no-body>
          <b-card-header>
            <b-card-title>Utilisateurs</b-card-title>
            <b-dropdown
              text=""
              variant="transparent"
              class="chart-dropdown "
              right
              no-caret
              toggle-class="p-0 mb-25"
              size="sm"
            >
              <b-dropdown-item
                v-for="day in chartData.lastDays"
                :key="day"
              >
                {{ day }}
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>

          <b-card-body>

            <!-- apex chart -->
            <vue-apex-charts
              type="donut"
              height="400"
              class="my-1"
              :options="sessionsByDeviceDonut.chartOptions"
              :series="sessionsByDeviceDonut.series"
            />

            <!-- chart info -->
            <div
              v-for="(data,index) in chartData.chartInfo"
              :key="data.name"
              class="d-flex justify-content-between"
              :class="index === chartData.chartInfo.length - 1 ? 'mb-0':'mb-1'"
            >
              <div class="series-info d-flex align-items-center">
                <feather-icon
                  :icon="data.icon"
                  size="16"
                  :class="data.iconColor"
                />
                <span class="font-weight-bolder ml-75 mr-25">{{ data.name }}</span><span>- {{ data.usage }}%</span>
              </div>
              <div>
                <span>{{ data.upDown }}%</span>
                <feather-icon
                  :icon="data.upDown > 0 ? 'ArrowUpIcon':'ArrowDownIcon'"
                  :class="data.upDown > 0 ? 'text-success':'text-danger'"
                  class="mb-25 ml-25"
                />
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        md="6"
        sm="6"
        class="kb-search-content"
      >
        <b-card no-body>
          <b-card-header>
            <b-card-title>Sociétés</b-card-title>
            <b-dropdown
              text=""
              variant="transparent"
              class="chart-dropdown "
              right
              no-caret
              toggle-class="p-0 mb-25"
              size="sm"
            >
              <b-dropdown-item
                v-for="day in chartData.lastDays"
                :key="day"
              >
                {{ day }}
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>

          <b-card-body>

            <!-- apex chart -->
            <vue-apex-charts
              type="pie"
              height="325"
              class="mt-2 mb-1"
              :options="customersPie.chartOptions"
              :series="customersPie.series"
            />

            <!-- list group -->
            <div class="pt-25">
              <div
                v-for="(data,index) in chartData.listData"
                :key="index"
                class="d-flex justify-content-between"
                :class="index === Object.keys(chartData.listData).length - 1 ? '':'mb-1'"
              >
                <div class="series-info">
                  <feather-icon
                    :icon="data.icon"
                    size="16"
                    class="mr-50"
                    :class="data.iconColor"
                  />
                  <span class="font-weight-bolder">{{ data.text }}</span>
                </div>
                <span>{{ data.result }}</span>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

  </section>

</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $barColor = '#f3f3f3'
const $trackBgColor = '#EBEBEB'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    VueApexCharts,
  },

  setup() {
    const statisticsOrder = {
      series: [
        {
          name: '2020',
          data: [45, 85, 65, 45, 65],
        },
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
            top: -15,
            bottom: -15,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '20%',
            startingShape: 'rounded',
            colors: {
              backgroundBarColors: [$barColor, $barColor, $barColor, $barColor, $barColor],
              backgroundBarRadius: 5,
            },
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        colors: [$themeColors.warning],

        xaxis: {
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
        tooltip: {
          x: {
            show: false,
          },
        },
      },
    }
    const statisticsProfit = {
      series: [
        {
          data: [0, 20, 5, 30, 15, 45],
        },
      ],
      chartOptions: {
        chart: {

          type: 'line',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        grid: {
          borderColor: $trackBgColor,
          strokeDashArray: 5,
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            top: -30,
            bottom: -10,
          },
        },
        stroke: {
          width: 3,
        },
        colors: [$themeColors.info],
        markers: {
          size: 2,
          colors: $themeColors.info,
          strokeColors: $themeColors.info,
          strokeWidth: 2,
          strokeOpacity: 1,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [
            {
              seriesIndex: 0,
              dataPointIndex: 5,
              fillColor: '#ffffff',
              strokeColor: $themeColors.info,
              size: 5,
            },
          ],
          shape: 'circle',
          radius: 2,
          hover: {
            size: 3,
          },
        },
        xaxis: {
          labels: {
            show: true,
            style: {
              fontSize: '0px',
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
        tooltip: {
          x: {
            show: false,
          },
        },
      },
    }
    const chartData = ref({})
    const sessionsByDeviceDonut = {
      series: [20.6, 7, 6.5, 5, 4, 3, 5, 4],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        labels: [
          'Gestionaire Financier',
          'Admin Syndic',
          'Super Admin',
          'Copropriétaire',
          'Locataires',
          'Planificateur de Tâches',
          'Société de Prestations de Services',
          'Société de Contrôle de Services',
        ],
        dataLabels: {
          enabled: false,
        },
        legend: { show: true },
        comparedResult: [2, -3, 8, 2, -3, 8, 2, -3],
        stroke: { width: 0 },
        colors: ['rgba(25, 43, 178, 0.7)', 'rgba(228, 161, 27, 0.7)', 'rgba(220, 76, 100, 0.7)', 'rgba(25, 43, 178, 0.7)', 'rgba(228, 161, 27, 0.7)', 'rgba(220, 76, 100, 0.7)', 'rgba(25, 43, 178, 0.7)', 'rgba(228, 161, 27, 0.7)'],
      },

    }
    const customersPie = {
      series: [690, 258, 149],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        labels: [
          'Société de Prestations de Services',
          'Société de Contrôle de Services',
          'Société Syndic',
        ],
        dataLabels: {
          enabled: false,
        },
        legend: { show: true },
        stroke: {
          width: 4,
        },
        colors: ['rgba(25, 43, 178, 0.7)', 'rgba(228, 161, 27, 0.7)', 'rgba(220, 76, 100, 0.7)'],
      },
    }

    return {
      statisticsOrder,
      statisticsProfit,
      chartData,
      sessionsByDeviceDonut,
      customersPie,
    }
  },

}

</script>

<style>

</style>
